<template>
  <div class="entry-view-holder esperienza">
    <loader
      v-if="item == null || loading"
      :show="item == null"
      :width="40"
      :height="40"
    ></loader>
    <top-bar
      v-if="item != null"
      :title="id == 'new' ? 'Inserimento Esperienza' : 'Modifica Esperienza'"
      :preview="item.contents[lang].permalink + '?preview=1'"
      :action="`Salva`"
      :actionIsValid="isValid"
      :loading="loading || item == null"
      @lang="
        (l) => {
          lang = l;
          $v.$touch();
        }
      "
      @onAction="save"
    />

    <div v-if="item && item.draft > 0">
      <div class="alert alert-info alert-with-icon">
        <button type="button" aria-hidden="true" class="close">×</button>
        <span data-notify="icon" class="ti-bell"></span>
        <span data-notify="message">Scheda BOZZA di Revisione</span>
      </div>
    </div>
    <form :class="['form', 'row']" novalidate v-if="item != null">
      <div class="col-md-9">
        <card title="Informazioni di base" :collapse="false">
          <content>
            <textbox
              title="Titolo"
              :v="$v.item.contents[lang].title"
              v-model="item.contents[lang].title"
            />
            <textbox
              title="Slug"
              :v="$v.item.contents[lang].slug"
              v-model="item.contents[lang].slug"
            />
            <hr />
            <div class="form-group">
              <label>Descrizione</label>
              <rich-editor
                :v="$v.item.contents[lang].description"
                v-model="item.contents[lang].description"
              />
            </div>

            <div class="form-group">
              <label>Tags</label>
              <tag-manager
                aria-required="false"
                aria-invalid="false"
                v-model="item.contents[lang].tags"
                :canAddTags="canAddTags"
                :language="lang"
              />
            </div>
          </content>
          <footer></footer>
        </card>

        <card title="Informazioni seo" :collapse="false">
          <content>
            <fieldset>
              <h5>SEO</h5>
              <textbox
                title="Keywords"
                :v="$v.item.contents[lang].google.keywords"
                v-model="item.contents[lang].google.keywords"
              />
              <textbox
                title="Titolo"
                :v="$v.item.contents[lang].google.title"
                v-model="item.contents[lang].google.title"
              />
              <a-textarea
                title="Meta Descrizione"
                :maxLength="170"
                :v="$v.item.contents[lang].google.description"
                v-model="item.contents[lang].google.description"
              />
            </fieldset>
            <fieldset>
              <h5>Social</h5>
              <textbox
                title="Title"
                :v="$v.item.contents[lang].social.title"
                v-model="item.contents[lang].social.title"
              />
              <a-textarea
                title="Meta Descrizione"
                :v="$v.item.contents[lang].social.description"
                v-model="item.contents[lang].social.description"
              />
            </fieldset>
          </content>
          <footer></footer>
        </card>

        <card title="Informazioni aggiuntive" :collapse="false">
          <textbox title="Costo" :v="$v.item.price" v-model="item.price[lang]" />
          <!-- Nuovo componente operatori -->
          <!-- ATTENZIONE AL V-MODEL -->
          <operatori-combo-multiselect
            v-model="item.operatore"
            city-name=""
            provincia=""
          ></operatori-combo-multiselect>
          <!-- comune -->
          <city-combo-multiselect
            class="mt-2"
            v-model="item.comune"
            city-name=""
            provincia=""
          >
          </city-combo-multiselect>
        </card>

        <card title="Contatti" :collapse="false">
          <textbox
            title="Referente"
            :v="$v.item.referente[lang]"
            v-model="item.referente[lang]"
          />
          <contacts-editor
            v-model="item.contacts[lang]"
            :lang="lang"
          ></contacts-editor>
        </card>

        <card title="Immagini" :collapse="false">
          <attachments-box v-model="item.attachments"></attachments-box>
        </card>
      </div>

      <!-- SIDEBAR -->
      <div class="col-md-3">
        <card title="Impostazioni">
          <statuses-box
            v-model="item.contents[lang].status"
            title="false"
          ></statuses-box>
          <author-box v-model="item.contents[lang].author"></author-box>
          <date-picker v-model="item.contents[lang].pub_date"></date-picker>
        </card>

        <card title="Categorie" :collapse="false">
          <fieldset>
            <h5>Reti</h5>
            <category-combo
              :mainCategory="5"
              v-model="item.theme"
            ></category-combo>
          </fieldset>
          <hr />
        </card>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

import TopBar from "@/components/altrama/TopBar.vue";
import Textbox from "@/components/altrama/form/Textbox";
import RichEditor from "@/components/altrama/RichEditor";
import TagManager from "@/components/altrama/TagManager";
import ATextarea from "@/components/altrama/form/ATextarea";
import OperatoriComboMultiselect from "../../OperatoriComboMultiselect";
import CityComboMultiselect from "@/components/altrama/CityComboMultiselect";
import ContactsEditor from "@/components/altrama/ContactsEditor";
import AttachmentsBox from "@/components/altrama/AttachmentsBox";
import StatusesBox from "@/components/altrama/StatusesBox";
import AuthorBox from "@/components/altrama/AuthorBox";
import DatePicker from "@/components/altrama/DatePicker";
import CategoryCombo from "@/components/altrama/CategoryCombo";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    TopBar,
    Textbox,
    RichEditor,
    TagManager,
    ATextarea,
    OperatoriComboMultiselect,
    CityComboMultiselect,
    ContactsEditor,
    AttachmentsBox,
    StatusesBox,
    AuthorBox,
    DatePicker,
    CategoryCombo,
  },

  props: ["id", "operatore"],
  mixins: [validationMixin],

  validations() {
    if (
      !!this.item &&
      !!this.item.contents[this.lang] &&
      this.item.contents[this.lang].status == 1
    ) {
      return {
        item: {
          contents: this.$contentTemplate({
            title: { required },
            description: { required },
            excerpt: { required },
            google: {
              description: { required },
              keywords: { required },
              title: { required },
            },
            slug: { required },
            social: { description: { required }, title: { required } },
          }),
          referente: {
            it: "",
            en: "",
          },
        },
      };
    }
    return {
      item: {
        contents: this.$contentTemplate({
          title: { required },
          description: "",
          excerpt: "",
          google: { description: "", keywords: "", title: "" },
          slug: "",
          social: { description: "", title: "" },
          subtitle: "",
        }),
        referente: {
          it: "",
          en: "",
        },
        contacts: {
          it: [],
          en: [],
        },
      },
    };
  },

  data() {
    return {
      needSave: false,
      hasErrors: false,
      loading: true,
      item: null,
      lang: "it",
    };
  },

  computed: {
    isValid: function () {
      if (!this.item.canSave) {
        if (this.item.contents[this.lang].status == 1) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
    template: function () {
      const userId = Number(this.$api.user.current().id);
      return {
        id: "new",
        contents: this.$contentTemplate({
          description: "",
          costo: "",
          excerpt: "",
          google: { description: "", keywords: "", title: "" },
          slug: "",
          social: { description: "", title: "" },
          subtitle: "",
          title: "",
          tags: [],
          author: userId,
          status: 2,
          permalink: "",
          pub_date: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        }),
        category: [],
        attachments: [],
        target: [],
        theme: [],
        destinazione: [],
        contacts: {
          it: [],
          en: [],
        },
        referente: {
          it: "",
          en: "",
        },
        price: {
          it: "",
          en: "",
        },
        location: {},
        destination: {},
        portal: [
          {
            id: 1,
            active: false,
          },
          {
            id: 2,
            active: false,
          },
          {
            id: 3,
            active: false,
          },
        ],
        comune: [],
        canSave: false,
        created_at: "",
        updated_at: "",
        operatore: [],
      };
    },
  },

mounted: async function () {
    this.canAddTags = await this.$api.user.can('view-tag');
    this.loadData();
  },

  methods: {
    loadData: function () {
      let self = this;
      self.item = null;

      if (this.id == "new") {
        self.item = JSON.parse(JSON.stringify(self.template));
        self.needSave = false;
        self.loading = false;
        return;
      }

      self.loading = true;

      axios({
        method: "get",
        url: `${self.$url}esperienze/${self.id}`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.item = _.merge(
            JSON.parse(JSON.stringify(self.template)),
            response.data.data
          );
          self.loading = false;
          self.needSave = false;
        })
        .catch(function (error) {
          self.loading = false;
          self.hasErrors = true;
         self.$processError(error);
        });
    },

    save() {
      this.$v.$touch();
      if (!this.$v.item.contents[this.lang].$anyError) {
        let self = this;
        let url = `${self.$url}esperienze/${self.id}`;
        let method = "put";

        if (this.item.id == "new") {
          method = "post";
          url = `${self.$url}esperienze`;
        }

        this.item.lang = this.lang;
        this.loading = true;

        axios({
          method: method,
          url: url,
          headers: this.$config.axiosHeaders(),
          data: this.item,
        })
          .then(function (response) {
            self.loading = false;
            self.$notifyVue(
              "top",
              "center",
              response.data.status,
              response.data.data.message,
              "ti-check"
            );
            if (response.data.data.id !== self.item.id) {
              self.item.id = response.data.data.id;
              self.$router.push({ params: { id: self.item.id } });
            }
          })
          .catch(function (error) {
            self.loading = false;
            self.hasErrors = true;
          self.$processError(error);
          });

        this.needSave = false;
      }
      console.log("save");
      //console.log(JSON.stringify(this.item));
    },
  },
    watch: {
      id: {
        handler(val, oldVal) {
          this.loadData();
        },
        deep: true,
      },
    }
};
</script>

<style scoped>
</style>
