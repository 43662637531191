<template>
  <div class="entry-view-holder templates">
    <loader
      v-if="item == null || loading"
      :show="item == null"
      :width="40"
      :height="40"
    />

    <top-bar
      v-if="item != null"
      :title="id == 'new' ? 'Inserimento Template' : 'Modifica Template'"
      :action="`Salva`"
      :actionIsValid="isValid"
      :hideLang="true"
      :loading="loading || item == null"
      @onAction="save"
    />
    <form :class="['form', 'row']" v-if="item != null">
      <div class="col-md-12">
        <card title="Informazioni templates" :collapsed="false">
            <textbox
              title="Titolo"
              v-model="item.name"
            />
            <textbox
              title="View"
              v-model="item.view"
            />
            <label> Parametri</label>
            <textarea
              class="w-100"
              v-model="item.params"
            />
        </card>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

import Textbox from "@/components/altrama/form/Textbox.vue";
import TopBar from "@/components/altrama/TopBar";

export default {
  components: {
    Textbox,
    TopBar
  },

  props: ["id"],
  data() {
    return {
      loading: true,
      needSave: false,
      item: null,
    };
  },

  computed: {
    isValid() {
      return true;
    },

    template() {
      const userId = Number(this.$api.user.current().id);
      return {
        id: "new"
      };
    },
  },

  methods: {
    loadData() {
      let self = this;
      self.item = null;

      if (this.id === "new") {
        self.item = JSON.parse(JSON.stringify(this.template));
        self.needSave = false;
        self.loading = false;
        return;
      }
      self.loading = true;

      axios({
        method: "GET",
        url: `${self.$url}templates/${self.id}`,
        headers: this.$config.axiosHeaders(),
      })
        .then((response) => {
          self.item = _.merge(
            JSON.parse(JSON.stringify(self.template)),
            response.data.data
          );
          self.loading = false;
        })

        .catch((error) => {
          self.loading = false;
          console.log(error);
        });
    },

    save() {
        let self = this;
        let url = `${self.$url}templates/${self.id}`;
        let method = "put";

        if (this.item.id == "new") {
          method = "post";
          url = `${self.$url}templates`;
        }
        this.loading = true;
        axios({
          method: method,
          url: url,
          headers: this.$config.axiosHeaders(),
          data: this.item,
        })
          .then(function (response) {
            self.loading = false;
            self.$notifyVue(
              "top",
              "center",
              response.data.status,
              response.data.data.message,
              "ti-check"
            );
            if (response.data.data.id !== self.item.id) {
              self.item.id = response.data.data.id;
              self.$router.push({ params: { id: self.item.id } });
            }
          })
          .catch(function (error) {
            self.loading = false;
            self.hasErrors = true;
           self.$processError(error);
        });
      this.needSave = false;
    },
  },

mounted: async function () {
    this.canAddTags = await this.$api.user.can('view-tag');
    this.loadData();
  },
  watch: {
    id: {
      handler(val, oldVal) {
        this.loadData();
      },
      deep: true,
    },
  }
};
</script>

<style scoped>
</style>
