import { render, staticRenderFns } from "./Esperienza.vue?vue&type=template&id=665e2592&scoped=true&"
import script from "./Esperienza.vue?vue&type=script&lang=js&"
export * from "./Esperienza.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665e2592",
  null
  
)

export default component.exports